import React from 'react';
import ReactDOM from 'react-dom';

// TODO: change this later to just whatever is used
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

function Square(props) {
    return (
        <Button
            variant={"square " + ((props.variant != null) ? props.variant : "") + " btn-square"}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.value}
        </Button>
    );
}

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            squares: Array(9).fill(null),
            fillCount: 0,
        };
    }

    isFilled() {
        return (this.state.fillCount === 9);
    }

    handleClick(i) {
        const squares = this.state.squares.slice();
        if (calculateWinner(squares) || squares[i]) {
            return;
        }
        squares[i] = (this.state.fillCount % 2) ? 'O' : 'X';
        this.setState({
            squares: squares,
            fillCount: this.state.fillCount + 1,
        });
    }

    renderSquare(i, clickable) {
        const x = parseInt(i / 3);
        const y = (i % 3);
        const isCorner = ((x !== 1) && (y !== 1));
        if (isCorner) {
            let variant = "";
            if (y === 0) {
                if (x === 0)
                    variant = "top-left";
                else
                    variant = "bottom-left";
            } else {
                if (x === 0)
                    variant = "top-right";
                else
                    variant = "bottom-right";
            }
            return (
                <Square
                    value={this.state.squares[i]}
                    onClick={() => this.handleClick(i)}
                    variant={variant}
                    disabled={clickable}
                />
            )
        } else {
            return (
                <Square
                    value={this.state.squares[i]}
                    onClick={() => this.handleClick(i)}
                    disabled={clickable}
                />
            )
        }
    }

    reset() {
        this.setState({
            squares: Array(9).fill(null),
            fillCount: 0,
        });
    }

    render() {
        const winner = calculateWinner(this.state.squares);
        const clickability = (winner || this.isFilled()) ? true : false;
        let status;
        if (this.state.fillCount === 0) {
            status = (<>Start as <Badge bg="light" text="success fw-normal">X</Badge> ...</>)
        } else if (winner) {
            status = <>Winner: <Badge bg="light" text="success fw-normal">{winner}</Badge></>;
        } else if (this.isFilled()) {
            status = 'Draw!';
        } else {
            status = (<div className="d-flex align-items-center"><Col>Next player:</Col><Col><Badge bg="light" text="success fw-normal">{(this.state.fillCount % 2) ? 'O' : 'X'}</Badge></Col></div>);
        }
        return (
            <Card className="game shadow">
                <Badge bg=" p-2 fw-light game-info" text="secondary">{status}</Badge>
                <div className="board mx-auto">
                    <div className="row">
                        {this.renderSquare(0, clickability)}
                        {this.renderSquare(1, clickability)}
                        {this.renderSquare(2, clickability)}
                    </div>
                    <div className="row">
                        {this.renderSquare(3, clickability)}
                        {this.renderSquare(4, clickability)}
                        {this.renderSquare(5, clickability)}
                    </div>
                    <div className="row">
                        {this.renderSquare(6, clickability)}
                        {this.renderSquare(7, clickability)}
                        {this.renderSquare(8, clickability)}
                    </div>
                </div>
                <div className="text-center"><Button variant="outline-danger btn-reset shadow-sm" size='sm' onClick={() => this.reset()}>Reset</Button></div>
            </Card>
        );
    }
}

class Page extends React.Component {
    render() {
        return (
            <Container fluid="sm" className="jumbotron shadow-sm fw-light">
                <h1 className="text-center fw-bolder">Hi there!</h1>
                <p className="px-2 pt-2">You have reached a rather remote corner of the deep web! 🤪</p>
                <p className="px-2 pb-2">The author of this page plans to put stuff up here eventually; possibly a blog, or a website for an app he's currently working on. For now, here's a tic-tac-toe game instead!</p>
                <Row className='justify-content-center'><Game /></Row>
            </Container>
        )
    }
}

// ========================================

ReactDOM.render(
    <Page />,
    document.getElementById('root')
);

function calculateWinner(squares) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return squares[a];
        }
    }
    return null;
}
